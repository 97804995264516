export const menuItems = [
  { title: "ДОМА" },
  {
    title: "ПРОДУКТЫ",
    submenu: [
      {
        title: "Мебель",
        submenu: [
          {
            title: "Диваны",
          },
          {
            title: "Кресло",
          },
          {
            title: "Журнальный стол",
          },
          {
            title: "Комод",
          },
          {
            title: "Пуфы",
          },
          {
            title: "Стеллажи",
          },
        ],
      },
      {
        title: "Светильники",
        submenu: [
          {
            title: "Подвесной светильник",
          },
          {
            title: "Подвесная люстра",
          },
          {
            title: "Потолочный светильник",
          },
          {
            title: "Торшеры",
          },
          {
            title: "Настольные лампы",
          },
          {
            title: "Бра",
          },
        ],
      },
      {
        title: "Декор",
        submenu: [
          {
            title: "Картины",
          },
          {
            title: "УФ-печать",
          },
          {
            title: "Обои",
          },
          {
            title: "Панно из акрила",
          },
          {
            title: "Панно из металла",
          },
          {
            title: "Скульптура",
          },
        ],
      },
    ],
  },
  { title: "КОНТАКТ" },
  { title: "О НАС" },
];
