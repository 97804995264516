import Routers from "./router/Routers";
import {BrowserRouter} from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
        <Routers/>
    </BrowserRouter>
  );
}

export default App;
